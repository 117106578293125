import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import NotFound from '../components/NotFound';

const NotFoundPage = () => {
  const browser = typeof window !== "undefined" && window;
  return browser && (
    <Layout hideFooter pageTitle={"404: Not found"}>
      <SEO title="404: Not found" />
      <NotFound />
    </Layout>
  )
}

export default NotFoundPage;
