import React from 'react';
import notfound from '../../images/notfound.svg';
import './NotFound.scss';

export default function NotFound({}) {
  const goToHome = () => {
    window.location.href = 'https://mosaicmoney.app/';
  };

  return (
    <section id="not-found">
      <img al={'404'} src={notfound} alt={'404'}></img>
      <p>THE PAGE YOU WERE LOOKING FOR DOES NOT EXIST.</p>
      <button onClick={goToHome}>GO TO HOME</button>
    </section>
  );
}
